import { ELegalRelationType, ETermsCategory, ETermsProductType, ETermsProvider, ETermsType } from "../type/terms/ETermsType"

export interface ITermsTemplateType {
    relationTypes: ELegalRelationType[]
    productType?: ETermsProductType
    provider?: ETermsProvider
    category: ETermsCategory
    label: string
    exampleData: { [key: string]: any }
}

const swiipeBaseData = {
    DataProtectionEmail: "dataprotection@swiipe.com",
    SwiipeName: "Swiipe ApS",
    SwiipeCVR: "39 06 01 08",
    SwiipeAddress: "Ryvangs Allé 91",
    SwiipeZip: "2900",
    SwiipeCity: "Hellerup",
    SwiipePhone: "+45 7196 9616",
    SwiipeSupportEmail: "support@swiipe.com",
    SwiipeContactEmail: "contact@swiipe.com",

    SwiipeSiteUrl: "https://www.swiipe.com",
    SwiipeSiteHostName: "www.swiipe.com",
    SwiipePortalUrl: "https://portal.swiipe.com",
    SwiipePortalHostName: "portal.swiipe.com",
    SwiipeDevPortalUrl: "https://developer.swiipe.com",
    SwiipeDevPortalHostName: "developer.swiipe.com",
    SwiipeSupportUrl: "https://support.swiipe.com",
    SwiipeSupportHostName: "support.swiipe.com",
}

const termsTemplateTypeSpecs: { [type in ETermsType]: ITermsTemplateType } = {
    TermsMerchant: {
        label: "Terms Merchant",
        category: "GeneralTerms",
        relationTypes: ["Merchant"],
        exampleData: {
            ...swiipeBaseData,
        },
    },
    TermsUser: {
        label: "Terms User",
        category: "GeneralTerms",
        relationTypes: ["User"],
        exampleData: {
            ...swiipeBaseData,
        },
    },
    DataProcessingAgreement: {
        label: "Data Processing Agreement",
        category: "Agreement",
        relationTypes: ["User", "Merchant", "Partner"],
        exampleData: {
            ...swiipeBaseData,
        },
    },
    TermsPartner: {
        label: "Terms Partner",
        category: "GeneralTerms",
        relationTypes: ["Partner"],
        exampleData: {
            ...swiipeBaseData,
        },
    },
    MerchantPartnerAgreement: {
        label: "Merchant Partner Agreement",
        category: "Agreement",
        relationTypes: ["Merchant"],
        exampleData: {
            ...swiipeBaseData,
        },
    },
    PrivacyPolicyShared: {
        label: "Privacy Policy Shared",
        category: "PrivacyPolicy",
        relationTypes: ["User", "Merchant", "Partner"],
        exampleData: {
            ...swiipeBaseData,
        },
    },
    TermsReturnSell: {
        label: "Terms Return-sell",
        category: "GeneralTerms",
        relationTypes: ["Merchant"],
        productType: "ReturnSell",
        exampleData: {
            ...swiipeBaseData,
        },
    },
    TermsPlusSell: {
        label: "Terms Plus-sell",
        category: "GeneralTerms",
        relationTypes: ["Merchant"],
        productType: "PlusSell",
        exampleData: {
            ...swiipeBaseData,
        },
    },
    TermsAcquiringClearhaus: {
        label: "Terms Acquiring Clearhaus",
        category: "GeneralTerms",
        relationTypes: ["Merchant"],
        productType: "Acquiring",
        provider: "Clearhaus",
        exampleData: {
            ...swiipeBaseData,
        },
    },
    TermsA2a: {
        label: "Terms A2A",
        category: "GeneralTerms",
        relationTypes: ["Merchant"],
        productType: "A2A",
        exampleData: {
            ...swiipeBaseData,
        },
    },
    TermsWinBack: {
        label: "Terms Win-back",
        category: "GeneralTerms",
        relationTypes: ["Merchant"],
        productType: "WinBack",
        exampleData: {
            ...swiipeBaseData,
        },
    },
    TermsAnywhereMobile: {
        label: "Terms Anywhere Mobile",
        category: "GeneralTerms",
        relationTypes: ["Merchant"],
        productType: "AnywhereMobile",
        exampleData: {
            ...swiipeBaseData,
        },
    },
    TermsPSP: {
        label: "Terms PSP",
        category: "GeneralTerms",
        relationTypes: ["Merchant"],
        productType: "PSP",
        exampleData: {
            ...swiipeBaseData,
        },
    },
    TermsKlarna: {
        label: "Terms Klarna",
        category: "GeneralTerms",
        relationTypes: ["Merchant"],
        productType: "Klarna",
        exampleData: {
            ...swiipeBaseData,
        },
    },
    TermsSwish: {
        label: "Terms Swish",
        category: "GeneralTerms",
        relationTypes: ["Merchant"],
        productType: "Swish",
        exampleData: {
            ...swiipeBaseData,
        },
    },
    TermsApplePay: {
        label: "Terms ApplePay",
        category: "GeneralTerms",
        relationTypes: ["Merchant"],
        productType: "ApplePay",
        exampleData: {
            ...swiipeBaseData,
        },
    },
    TermsDankort: {
        label: "Terms Dankort",
        category: "GeneralTerms",
        relationTypes: ["Merchant"],
        productType: "Dankort",
        exampleData: {
            ...swiipeBaseData,
        },
    },
    TermsForbrugsforeningen: {
        label: "Terms Forbrugsforeningen",
        category: "GeneralTerms",
        relationTypes: ["Merchant"],
        productType: "Forbrugsforeningen",
        exampleData: {
            ...swiipeBaseData,
        },
    },
    TermsMobilePayOnline: {
        label: "Terms MobilePayOnline",
        category: "GeneralTerms",
        relationTypes: ["Merchant"],
        productType: "MobilePayOnline",
        exampleData: {
            ...swiipeBaseData,
        },
    },
    TermsViaBill: {
        label: "Terms ViaBill",
        category: "GeneralTerms",
        relationTypes: ["Merchant"],
        productType: "ViaBill",
        exampleData: {
            ...swiipeBaseData,
        },
    },
    TermsAnyday: {
        label: "Terms Anyday",
        category: "GeneralTerms",
        relationTypes: ["Merchant"],
        productType: "Anyday",
        exampleData: {
            ...swiipeBaseData,
        },
    },
    TermsResurs: {
        label: "Terms Resurs",
        category: "GeneralTerms",
        relationTypes: ["Merchant"],
        productType: "Resurs",
        exampleData: {
            ...swiipeBaseData,
        },
    },
    TermsVipps: {
        label: "Terms Vipps",
        category: "GeneralTerms",
        relationTypes: ["Merchant"],
        productType: "Vipps",
        exampleData: {
            ...swiipeBaseData,
        },
    },
    TermsExternalAnalytics: {
        label: "Terms External Analytics",
        category: "GeneralTerms",
        relationTypes: ["Merchant"],
        productType: "Dashboard",
        exampleData: {
            ...swiipeBaseData,
        },
    },
    TermsTrygUser: {
        label: "Terms Tryg",
        category: "GeneralTerms",
        relationTypes: ["User"],
        productType: "Tryg",
        exampleData: {
            ...swiipeBaseData,
        },
    },
}

export default termsTemplateTypeSpecs
