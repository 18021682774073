import { useEffect, useMemo, useRef, useState } from "react"
import React, { SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import {
    AcquiringBusinessModelFields,
    AcquiringContactFields,
    AcquiringTurnoverFields,
    CompanyNameAndCVRInput,
    CountryInputComp,
    DropDownList,
    FieldsetFormGroup,
    FloatingLabelInput,
    FormActionSection,
    FormFieldsSection,
    FormHeader,
    FormInputColumnWithInfoColumn,
    IAcquiringBusinessModelSubForm,
    IAcquiringContactFirstAndLastNameSubForm,
    IAcquiringTurnoverSubForm,
    ICompanyNameAndCVRInputModel,
    PageContainer,
    PageWrapper,
    SpinnerContainer,
    StandardButtonWithSpinner,
    TClearhausDeliveryDelayType,
    TOrganizationType,
    TextButton,
    TitleWithAbsoluteHint,
    companyNumberLookupSupportedCountries,
    fetchOrganizationCompanyDetailsThunk,
    fetchSwMerchantWithIdThunk,
    getClearhausDeliveryDelayOptions,
    getExampleEmail,
    getLanguage,
    getMerchantAcquiringThunk,
    getSearchBarOptions,
    mapCountryToMarket,
    organizationSelectors,
    setMerchantAcquiringThunk,
    swMerchantSelectors,
    useRefState,
    useSelector,
    userSelectors,
    valFuncRequired,
} from "swiipe.portal.shared"
import SubmitButton from "../../components/buttons/SubmitButton"
import MerchantCustomPricesForm from "../../components/form/billing/MerchantCustomPricesForm"
import { AddWebshopsForm, IWebshopSubForm } from "../../components/merchant/AddWebshopsForm"
import { MerchantOnboardingServicesForm } from "../../components/merchantOnboarding/MerchantOnboardingServicesForm"
import {
    getActivePriceCategoriesFromSubForm,
    getPriceFormErrors,
    getPriceSectionTypesFromPriceCategories,
    preparePriceFormForUpdate,
} from "../../services/merchantCustomPricesService"
import {
    IMerchantPreOnboardingForm,
    getErrorForServicesSelection,
    mapMerchantPreOnboardingDetailsToForm,
    mapMerchantPreOnboardingFormToAcquiringKyc,
} from "../../services/merchantPreOnboardingService"
import { StoreState } from "../../store/StoreState"
import { invoicingSelectors } from "../../store/reducers/invoicingReducer"
import {
    IMerchantPreOnboardingDetailsState,
    merchantPreOnboardingSelectors,
} from "../../store/reducers/merchantPreOnboardingSlice"
import { IMerchantCreateStatusResponse, merchantSelectors } from "../../store/reducers/merchantReducer"
import { getInvoicingMerchantConfigThunk, setInvoicingMerchantConfigThunk } from "../../store/thunks/invoicingThunks"
import {
    createMerchantPreOnboardingThunk,
    getMerchantPreOnboardingDetailsThunk,
    saveDraftMerchantPreOnboardingsThunk,
} from "../../store/thunks/merchantPreOnboardingThunks"
import { getMerchantCreateStatusThunk } from "../../store/thunks/merchantThunks"
import { addModalThunk } from "../../store/thunks/modalThunks"
import { fetchUserByIdThunk } from "../../store/thunks/userThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import "./MerchantPreOnboardingPage.scss"

interface IMerchantPreOnboardingPageProps {
    draft?: string
    isDraft?: boolean
    anyCompanyDetails?: boolean
    id: string
    swMerchantId?: string
    refreshData: () => Promise<void>
    onGoBack: () => void
}

const MerchantPreOnboardingPage = ({
    id,
    swMerchantId,
    draft,
    isDraft,
    anyCompanyDetails,
    refreshData,
    onGoBack,
}: IMerchantPreOnboardingPageProps) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()
    const localSaveRef = useRef<NodeJS.Timeout | undefined>()
    const [showErrors, setShowErrors] = useState(false)
    const [webshopsDefaultLength, setWebshopsDefaultLength, webshopsDefaultLengthWatcher] = useRefState<number | undefined>(
        undefined
    )
    const userData = useSelector(userSelectors.userData)
    const [initialPriceSections, setInitialPriceSections] = useState<string[] | undefined>(undefined)

    const [delayReset, setDelayReset] = useState<{ delay: boolean; lastReset: IMerchantPreOnboardingForm | undefined }>({
        delay: false,
        lastReset: undefined,
    })

    const isEditCreated = !!id && id !== "new" && !isDraft

    const {
        control,
        formState,
        handleSubmit,
        clearErrors,
        trigger,
        setError,
        reset: resetFromLib,
        setValue,
        watch,
    } = useForm<IMerchantPreOnboardingForm>({
        mode: "onChange",
        reValidateMode: "onChange",
        shouldFocusError: true,
    })

    const reset = (values: IMerchantPreOnboardingForm) => {
        if (values && delayReset.delay) {
            setDelayReset({
                ...delayReset,
                lastReset: values,
            })
        } else {
            resetFromLib(values)
        }
    }

    useWatch({
        control,
        name: "company.country",
    })

    useWatch({
        control,
        name: ["company.companyNumber", "contact.email"],
    })

    const formValues = control.getValues()
    const currentCountry = formValues?.company?.country ?? "DK"
    const currentCompanyNumber = formValues?.company?.companyNumber
    const currentOwnerEmail = formValues?.contact?.email
    const companyNumberChangedTimeoutRef = useRef<NodeJS.Timeout | undefined>()
    const createMerchantStatus = useSelector((state: StoreState) =>
        merchantSelectors.merchantCreateStatus(state, currentCompanyNumber)
    )
    useEffect(() => {
        if (companyNumberChangedTimeoutRef.current) {
            clearTimeout(companyNumberChangedTimeoutRef.current)
        }
        const searchBarOptions = getSearchBarOptions(currentCompanyNumber, currentCountry)
        if (!searchBarOptions.isValidVat || (!searchBarOptions.usingCvrApi && !searchBarOptions.isVatSameWithSelectedCountry)) {
            return
        }
        companyNumberChangedTimeoutRef.current = setTimeout(() => {
            dispatch(getMerchantCreateStatusThunk(currentCompanyNumber))
        }, 500)
    }, [currentCompanyNumber])

    const publicInvoicingPrices = useSelector((state: StoreState) =>
        invoicingSelectors.publicPrices(state, mapCountryToMarket(currentCountry))
    )
    const invoicingMerchantConfig = useSelector((state: StoreState) =>
        invoicingSelectors.merchantConfig(state, swMerchantId ?? "")
    )

    const contactInfoValidationField: (keyof IAcquiringContactFirstAndLastNameSubForm)[] = [
        "firstName",
        "lastName",
        "email",
        "phone",
    ]

    const companyInfoValidationField: (keyof ICompanyNameAndCVRInputModel)[] = [
        "companyAddress",
        "companyCity",
        "companyName",
        "companyNumber",
        "companyPostcode",
        "country",
    ]

    const webshopInfoValidationField: (keyof IWebshopSubForm)[] = ["domain", "platform"]

    const businessInfoValidationField: (keyof IAcquiringBusinessModelSubForm<TClearhausDeliveryDelayType>)[] = [
        "delayComment",
        "deliveryDelay",
        "description",
        "sellPhysical",
        "sellSubscription",
        "tradeName",
        "useDropShipping",
    ]

    useEffect(() => {
        // This useEffect must be placed before other useEffects setting the webshopsDefaultLength, because it is a ref
        if (webshopsDefaultLength.current) {
            setWebshopsDefaultLength(undefined)
        }
    }, [webshopsDefaultLengthWatcher])

    const [formFromNonDraft, preOnboardingDetailsFromNonDraft] = useMerchantPreOnboardingFormFromNonDraftData(id, !!isDraft)
    useEffect(() => {
        if (formFromNonDraft) {
            reset(formFromNonDraft)
            setWebshopsDefaultLength(formFromNonDraft.webshops?.length)
            setInitialPriceSections(undefined)
        }
    }, [formFromNonDraft])

    useEffect(() => {
        const lastFormInput = localStorage.getItem("merchant_pre_onboarding_form")
        if (lastFormInput && id === "new") {
            try {
                const parsedLastFormInput = JSON.parse(lastFormInput)
                if (typeof parsedLastFormInput === "object") {
                    const form = parsedLastFormInput as IMerchantPreOnboardingForm
                    reset(form)
                    setWebshopsDefaultLength(form?.webshops?.length)
                    setInitialPriceSections(
                        getPriceSectionTypesFromPriceCategories(
                            getActivePriceCategoriesFromSubForm(form.prices ?? { priceOverrides: {}, customPrices: {} })
                        )
                    )
                    setTimeout(() => {
                        // Initial validation only seems to work if trigger is delayed
                        trigger()
                    }, 0)
                }
            } catch (err) {
                // Do nothing
            }
        }
        trigger()
    }, [])

    useEffect(() => {
        if (draft) {
            try {
                const parsedLastFormInput = JSON.parse(draft)
                if (typeof parsedLastFormInput === "object") {
                    const form = parsedLastFormInput as IMerchantPreOnboardingForm
                    reset(form)
                    setWebshopsDefaultLength(form?.webshops?.length)
                    setInitialPriceSections(
                        getPriceSectionTypesFromPriceCategories(
                            getActivePriceCategoriesFromSubForm(form.prices ?? { priceOverrides: {}, customPrices: {} })
                        )
                    )
                    setTimeout(() => {
                        // Initial validation only seems to work if trigger is delayed
                        trigger()
                    }, 0)
                }
            } catch (err) {
                // Do nothing
            }
        }
    }, [draft])

    useEffect(() => {
        if (id !== "new") {
            return
        }
        if (localSaveRef.current) {
            clearTimeout(localSaveRef.current)
        }
        localSaveRef.current = setTimeout(() => {
            if (id !== "new") {
                return
            }
            localStorage.setItem("merchant_pre_onboarding_form", JSON.stringify(control.getValues()))
        }, 2000)
    })

    const onSubmit: SubmitHandler<IMerchantPreOnboardingForm> = async (data) => {
        try {
            const servicesSelectionError = getErrorForServicesSelection(data.services)
            if (servicesSelectionError) {
                dispatch(
                    addModalThunk({
                        type: "error",
                        title: "Validation error",
                        errorMessage: servicesSelectionError,
                    })
                )
                return
            }
            const priceErrors = getPriceFormErrors(
                publicInvoicingPrices ?? [],
                data.prices ?? { priceOverrides: {}, customPrices: {} }
            )
            if (priceErrors.length > 0) {
                dispatch(
                    addModalThunk({
                        type: "error",
                        title: "Validation error",
                        errorMessage: priceErrors[0],
                    })
                )
                return
            }

            setDelayReset({ delay: true, lastReset: undefined })
            if (isEditCreated && formFromNonDraft) {
                data = {
                    ...data,
                    // Add disabled fields to data
                    company: { ...formFromNonDraft.company },
                    contact: { ...formFromNonDraft.contact },
                    language: formFromNonDraft.language,
                    merchantEmailMessage: formFromNonDraft.merchantEmailMessage,
                    webshops: [...formFromNonDraft.webshops],
                }
            }

            const preOnboardRes = await dispatch(createMerchantPreOnboardingThunk(data, id === "new" ? undefined : id))

            await dispatch(
                setMerchantAcquiringThunk(
                    mapMerchantPreOnboardingFormToAcquiringKyc(preOnboardRes.swMerchantId, preOnboardRes.webshopIds, data),
                    true
                )
            )

            const preparedPriceData = preparePriceFormForUpdate(
                publicInvoicingPrices ?? [],
                invoicingMerchantConfig ?? {
                    customPrices: [],
                    priceOverrides: [],
                },
                data.prices ?? { priceOverrides: {}, customPrices: {} }
            )

            await dispatch(
                setInvoicingMerchantConfigThunk(
                    preOnboardRes.swMerchantId,
                    {
                        priceOverrides: preparedPriceData.priceOverrides,
                        customPrices: preparedPriceData.customPrices,
                    },
                    preparedPriceData.idsToRemove
                )
            )

            await refreshData()
            localStorage.removeItem("merchant_pre_onboarding_form")
            onGoBack()
        } catch (err) {
            // Error handled in thunk
        }
        if (delayReset.lastReset) {
            resetFromLib(delayReset.lastReset)
        }
        setDelayReset({ delay: false, lastReset: undefined })
    }

    const showSpinner = isEditCreated && !formFromNonDraft

    return (
        <PageWrapper widest>
            <PageContainer classNameBody="merchant-pre-onboarding-page" title="Merchant onboarding" transparent>
                <FormHeader
                    onClose={() => onGoBack()}
                    title={(id === "new"
                        ? "Create new offer for e-commerce account"
                        : isEditCreated
                        ? "Edit offer for e-commerce account"
                        : "Edit draft offer for e-commerce account"
                    ).toUpperCase()}
                />
                {showSpinner && (
                    <SpinnerContainer
                        showSpinner={showSpinner}
                        className="merchant-pre-onboarding-page__spinner"
                    ></SpinnerContainer>
                )}
                {!showSpinner && (
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <FormFieldsSection
                            header={`1. Merchant contact info`}
                            validatedFields={[...contactInfoValidationField.map((f) => "contact." + f), "language"]}
                            formState={formState}
                            showErrors={showErrors}
                            showValidationCheckmark={true}
                        >
                            <AcquiringContactFields<IMerchantPreOnboardingForm>
                                control={control}
                                nameType="firstAndLastName"
                                showReview={false}
                                getFieldsFromControl={(form) => form?.contact}
                                savedFields={{} as IAcquiringContactFirstAndLastNameSubForm}
                                fieldSelectorPrefix="contact."
                                emailHintText="The offer will be sent to this email."
                                validatedFields={contactInfoValidationField.map((f) => "contact." + f)}
                                isDisabled={isEditCreated}
                            />
                            <FieldsetFormGroup field className="merchant-pre-onboarding-page__language">
                                <DropDownList
                                    placeholder="Language"
                                    name="language"
                                    options={[
                                        { text: "Danish", value: "da" },
                                        { text: "English", value: "en" },
                                        { text: "Swedish", value: "sv" },
                                    ]}
                                    disabled={isEditCreated}
                                    innerRef={control.register(valFuncRequired("You must choose a language"))}
                                />
                            </FieldsetFormGroup>
                        </FormFieldsSection>
                        <FormFieldsSection
                            header={`2. Company details`}
                            validatedFields={[
                                ...companyInfoValidationField.map((f) => "company." + f),
                                ...webshopInfoValidationField.map((f) => "webshops[*]." + f),
                            ]}
                            formState={formState}
                            showErrors={showErrors}
                            showValidationCheckmark={true}
                        >
                            <div>
                                <CreateMerchantStatus status={createMerchantStatus} ownerEmail={currentOwnerEmail} />
                                <FormInputColumnWithInfoColumn>
                                    <FieldsetFormGroup field>
                                        <CountryInputComp
                                            defaultValue={"DK"}
                                            disabled={isEditCreated}
                                            inputName="company.country"
                                            register={control.register}
                                            countryFilter={companyNumberLookupSupportedCountries}
                                        />
                                    </FieldsetFormGroup>
                                    <CompanyNameAndCVRInput<IMerchantPreOnboardingForm>
                                        clearError={clearErrors}
                                        errors={formState.errors.company}
                                        getValues={() => control.getValues()}
                                        register={control.register}
                                        setError={setError}
                                        setValue={setValue}
                                        watch={watch}
                                        fieldSelectorPrefix="company."
                                        getModelFromForm={(form) => form?.company ?? {}}
                                        isDisabled={isEditCreated}
                                        isInitiallyCompanyFieldsShown={anyCompanyDetails}
                                        resetCompanyFields={(companyModel) => {
                                            const values = control.getValues()
                                            reset({
                                                ...values,
                                                company: { ...values.company, ...companyModel },
                                            })
                                            setTimeout(() => {
                                                trigger()
                                            })
                                        }}
                                        allowGoBackToSearch
                                    />
                                </FormInputColumnWithInfoColumn>
                                <AddWebshopsForm
                                    control={control}
                                    fieldSelectorPrefix="webshops."
                                    getFieldsFromControl={(f) => f?.webshops}
                                    savedFields={[]}
                                    defaultLength={webshopsDefaultLength.current}
                                    isDisabled={isEditCreated}
                                />
                                <FormInputColumnWithInfoColumn
                                    className="mb-0 mt-4"
                                    infoColumn={
                                        <TitleWithAbsoluteHint
                                            noIcon
                                            title="This message will be shown to the merchant in the Acquiring application"
                                        />
                                    }
                                    infoNarrowWidth
                                >
                                    <FieldsetFormGroup field>
                                        <FloatingLabelInput
                                            name="merchantKycMessage"
                                            type="textarea"
                                            placeholder={"Your message to the merchant (acquiring application)"}
                                            innerRef={control.register()}
                                        ></FloatingLabelInput>
                                    </FieldsetFormGroup>
                                </FormInputColumnWithInfoColumn>
                            </div>
                        </FormFieldsSection>
                        <FormFieldsSection
                            header={`3. Business model`}
                            validatedFields={[...businessInfoValidationField.map((f) => "businessModel." + f)]}
                            formState={formState}
                            showErrors={showErrors}
                            showValidationCheckmark={true}
                        >
                            <AcquiringBusinessModelFields
                                control={control}
                                isSubmitted={false}
                                showMainInput={true}
                                showDetailInput={true}
                                getFieldsFromControl={(form) => form?.businessModel}
                                savedFields={{} as IAcquiringBusinessModelSubForm<TClearhausDeliveryDelayType>}
                                fieldSelectorPrefix={"businessModel."}
                                deliveryDelayOptions={getClearhausDeliveryDelayOptions()}
                                deliveryDelaysRequiringComment={[]}
                            />
                        </FormFieldsSection>
                        <FormFieldsSection
                            header={`4. Turnover`}
                            validatedFields={["turnover.transactionsCurrency", "turnover.monthly", "turnover.transactionAmount"]}
                            formState={formState}
                            showErrors={showErrors}
                            showValidationCheckmark={true}
                        >
                            <AcquiringTurnoverFields
                                control={control}
                                isSubmitted={false}
                                showMainInput={true}
                                showDetailInput={true}
                                getFieldsFromControl={(form) => form?.turnover}
                                savedFields={{} as IAcquiringTurnoverSubForm}
                                fieldSelectorPrefix={"turnover."}
                            />
                        </FormFieldsSection>
                        <FormFieldsSection
                            header={`5. Services`}
                            validatedFields={[]}
                            formState={formState}
                            showErrors={showErrors}
                            showValidationCheckmark={true}
                        >
                            <MerchantOnboardingServicesForm
                                control={control}
                                getFieldsFromControl={(form) => form?.services}
                                fieldSelectorPrefix={"services."}
                            />
                        </FormFieldsSection>
                        <FormFieldsSection
                            header={`6. Custom message (email)`}
                            validatedFields={["merchantEmailMessage"]}
                            formState={formState}
                            showErrors={showErrors}
                            showValidationCheckmark={true}
                        >
                            <div>
                                <FormInputColumnWithInfoColumn
                                    infoColumn={
                                        <TitleWithAbsoluteHint
                                            noIcon
                                            title="This message will be shown in the email sent to the merchant. It will be automatically sent from your email and signed in your name."
                                        />
                                    }
                                    infoNarrowWidth
                                >
                                    <FieldsetFormGroup field>
                                        <FloatingLabelInput
                                            name="merchantEmailMessage"
                                            type="textarea"
                                            disabled={isEditCreated}
                                            placeholder={"Your message to the merchant (email)"}
                                            innerRef={control.register(
                                                valFuncRequired("You must provide a message for the merchant email")
                                            )}
                                        ></FloatingLabelInput>
                                    </FieldsetFormGroup>
                                    <TextButton
                                        className="float-right"
                                        text="Preview email"
                                        fontSize="big"
                                        textDecoration="underline"
                                        onClick={async () => {
                                            const form = control.getValues()

                                            const baseData = {
                                                offerUrl: "javascript:void(0)",
                                                merchantContactFirstName: form.contact.firstName || "Hans",
                                                merchantContactLastName: form.contact.lastName || "Jensen",
                                                merchantName: form.company.companyName || "Random ApS",
                                                merchantContactEmail: form.contact.email || "rand.aps@gmail.com",
                                                salesFirstName: userData?.user.firstName,
                                                salesLastName: userData?.user.lastName,
                                                customMessage:
                                                    form.merchantEmailMessage.trim() ||
                                                    "[Your custom message goes here. For example: Thank you for the talk, here is the offer we discussed.]",
                                            }

                                            const email = await dispatch(
                                                getExampleEmail("", "MerchantOnboardingOffer", getLanguage(), baseData)
                                            )

                                            await dispatch(
                                                addModalThunk({
                                                    type: "email",
                                                    title: email.subject,
                                                    body: email.body,
                                                })
                                            )
                                        }}
                                    />
                                </FormInputColumnWithInfoColumn>
                            </div>
                        </FormFieldsSection>
                        <FormFieldsSection
                            header={`7. Custom prices and discounts`}
                            validatedFields={[]}
                            formState={formState}
                            showErrors={showErrors}
                            showValidationCheckmark={true}
                        >
                            <MerchantCustomPricesForm
                                control={control}
                                fieldSelectorPrefix="prices."
                                getFieldsFromControl={(form) => form?.prices}
                                market={mapCountryToMarket(currentCountry)}
                                isPreOnboarding={!isEditCreated}
                                invoicingMerchantConfig={invoicingMerchantConfig}
                                merchantOrPreOnboardingId={isEditCreated ? swMerchantId ?? "" : id}
                                sectionDisplayType="dropDownListChooseAndAdd"
                                sectionIndentLeftSize={110}
                                sectionIndentRightSize={90}
                                initialActiveSectionsForPreOnboarding={!isEditCreated ? initialPriceSections : undefined}
                                reset={(subForm) => {
                                    // Ignore - not needed - handled by page comp
                                }}
                            />
                        </FormFieldsSection>
                        <FormFieldsSection
                            header={`8. Custom message (offer view)`}
                            validatedFields={["merchantOfferMessage"]}
                            formState={formState}
                            showErrors={showErrors}
                            showValidationCheckmark={true}
                        >
                            <div>
                                <FormInputColumnWithInfoColumn
                                    infoColumn={
                                        <TitleWithAbsoluteHint
                                            noIcon
                                            title="This message will be displayed in the offer letter view. Please mention any trial prices, if applicable (they will not be shown in the pricing table)."
                                        />
                                    }
                                    infoNarrowWidth
                                >
                                    <FieldsetFormGroup field>
                                        <FloatingLabelInput
                                            name="merchantOfferMessage"
                                            type="textarea"
                                            placeholder={"Your message to the merchant (offer view)"}
                                            innerRef={control.register(
                                                valFuncRequired("You must provide a message for the merchant Offer letter")
                                            )}
                                        ></FloatingLabelInput>
                                    </FieldsetFormGroup>
                                    <TextButton
                                        className="float-right"
                                        text="Preview offer"
                                        fontSize="big"
                                        textDecoration="underline"
                                        onClick={async () => {
                                            const form = control.getValues()

                                            await dispatch(
                                                addModalThunk({
                                                    type: "merchantOfferPreview",
                                                    company: {
                                                        ...form.company,
                                                        companyEmail: form.contact.email,
                                                        companyPhone: form.contact.phone,
                                                        companyType: "",
                                                    },
                                                    countryCode: form.company.country,
                                                    created: new Date().toISOString(),
                                                    creator: {
                                                        email: userData?.user.email ?? "",
                                                        phone: userData?.user.phone ?? "",
                                                        firstName: userData?.user.firstName ?? "",
                                                        lastName: userData?.user.lastName ?? "",
                                                        shorthand: (userData?.user.email ?? "").split("@")[0],
                                                        userId: "",
                                                    },
                                                    owner: {
                                                        email: form.contact.email,
                                                        phone: form.contact.phone,
                                                        firstName: form.contact.firstName,
                                                        lastName: form.contact.lastName,
                                                        shorthand: form.contact.email.split("@")[0],
                                                        userId: "",
                                                    },
                                                    offerMessage: form.merchantOfferMessage,
                                                    prices: (publicInvoicingPrices ?? []).map((p) => {
                                                        const override = (form.prices ?? { priceOverrides: {}, customPrices: {} })
                                                            .priceOverrides[p.priceCategory]
                                                        return {
                                                            ...p,
                                                            amount: override?.price ? parseFloat(override?.price) : p.amount,
                                                            isActive: true,
                                                        }
                                                    }),
                                                    services: form.services,
                                                    webshops: form.webshops.map((webshopForm) => ({
                                                        hostName: webshopForm.domain,
                                                        platform: webshopForm.platform,
                                                        clientId: "",
                                                        mcc: webshopForm.mcc ?? "",
                                                        webshopId: "",
                                                        isDisabled: false,
                                                    })),
                                                })
                                            )
                                        }}
                                    />
                                </FormInputColumnWithInfoColumn>
                            </div>
                        </FormFieldsSection>
                        <FormActionSection
                            left={
                                id !== "new" && !isDraft ? undefined : (
                                    <StandardButtonWithSpinner
                                        isWide
                                        className="clearhaus-onboarding-message__left-button"
                                        inverted
                                        onClick={async () => {
                                            const currentForm = control.getValues()
                                            const draftData = JSON.stringify(currentForm)
                                            await dispatch(
                                                saveDraftMerchantPreOnboardingsThunk({
                                                    draftData,
                                                    estimatedValue: currentForm.turnover.monthly || 0,
                                                    estimatedValueCurrency: currentForm.turnover.transactionsCurrency || "DKK",
                                                    merchantName: currentForm.company.companyName || "None",
                                                    preOnboardingId: id === "new" ? undefined : id,
                                                })
                                            )
                                            await refreshData()
                                            localStorage.removeItem("merchant_pre_onboarding_form")
                                            onGoBack()
                                        }}
                                    >
                                        Save draft
                                    </StandardButtonWithSpinner>
                                )
                            }
                            middle={
                                <div>
                                    {preOnboardingDetailsFromNonDraft?.preOnboarding?.isSent && (
                                        <div className="clearhaus-onboarding-message__main-button-warning">
                                            Make sure to communicate any changes to the merchant directly, as they will not be
                                            receiving a new email.
                                        </div>
                                    )}
                                    <SubmitButton
                                        className="clearhaus-onboarding-message__main-button"
                                        onClick={() => setShowErrors(!!Object.keys(formState.errors).length)}
                                        formState={formState}
                                        dark
                                        isBlue
                                        noBorder
                                    >
                                        {preOnboardingDetailsFromNonDraft?.preOnboarding?.isSent ? "Update offer" : "Save & Send"}
                                    </SubmitButton>
                                </div>
                            }
                        />
                    </Form>
                )}
            </PageContainer>
        </PageWrapper>
    )
}

function CreateMerchantStatus(props: { status: IMerchantCreateStatusResponse | undefined; ownerEmail?: string }) {
    const email = props.ownerEmail?.toLowerCase().trim() ?? ""
    const status = props.status
    if (!status || !status.isAlreadyCreated) {
        return null
    }

    const renderBox = (message: string) => {
        return <div className="merchant-pre-onboarding-page__crn-status">{message}</div>
    }

    if (email !== status.ownerEmail) {
        return renderBox("You cannot create a merchant with a different owner email. The email must be " + status.ownerEmail)
    }
    if (status.isMarkedForDeletion) {
        if (status.isAllServicesStopped) {
            const deletiontext = status.isDeleted ? "The merchant is deleted" : "The merchant is marked for deletion"
            return renderBox(
                `${deletiontext}. If you create the merchant offer, the merchant deletion will be lifted and 90 days of trial will be given. After that, deletion will be reactivated unless the merchant has agreed to the offer.`
            )
        }
        return renderBox(
            `The merchant is marked for deletion, but it is still active. Cancel the deletion process in the Portal, before continuing with creating the offer.`
        )
    }
    return null
}

function useMerchantPreOnboardingFormFromNonDraftData(
    id: string,
    isDraft: boolean
): [IMerchantPreOnboardingForm | undefined, IMerchantPreOnboardingDetailsState | undefined] {
    const dispatch = useReduxDispatch()

    const model = useSelector((state: StoreState) => merchantPreOnboardingSelectors.details(state, id))
    const user = useSelector((state: StoreState) => userSelectors.userById(state, model?.preOnboarding.ownerId ?? ""))
    const acquiringKycs = useSelector((state: StoreState) =>
        swMerchantSelectors.acquiringKycs(state, model?.preOnboarding.swMerchantId ?? "")
    )
    const company = useSelector((state: StoreState) =>
        organizationSelectors.companyData(state, model?.preOnboarding.companyRegistrationNumber ?? "")
    )
    const swMerchantDetails = useSelector((state: StoreState) =>
        swMerchantSelectors.details(state, model?.preOnboarding.swMerchantId ?? "")
    )
    const invoicingMerchantConfig = useSelector((state: StoreState) =>
        invoicingSelectors.merchantConfig(state, model?.preOnboarding.swMerchantId ?? "")
    )

    const form = useMemo(
        () =>
            model && user && company && swMerchantDetails && invoicingMerchantConfig
                ? mapMerchantPreOnboardingDetailsToForm(
                      model.preOnboarding,
                      company,
                      user,
                      swMerchantDetails,
                      invoicingMerchantConfig,
                      acquiringKycs?.[0]
                  )
                : undefined,
        [model, user, company, swMerchantDetails, invoicingMerchantConfig, acquiringKycs]
    )

    useEffect(() => {
        if (!id || isDraft || id === "new") {
            return
        }

        dispatch(getMerchantPreOnboardingDetailsThunk({ preOnboardingId: id }))
    }, [id, isDraft])

    useEffect(() => {
        if (model && !model.preOnboarding.isDraft) {
            dispatch(
                fetchOrganizationCompanyDetailsThunk(
                    model.preOnboarding.swMerchantId,
                    model.preOnboarding.countryCode,
                    model.preOnboarding.companyRegistrationNumber,
                    TOrganizationType.SwMerchant
                )
            )
            dispatch(fetchUserByIdThunk(model.preOnboarding.ownerId, false))
            dispatch(fetchSwMerchantWithIdThunk(model.preOnboarding.swMerchantId, false))
            dispatch(getInvoicingMerchantConfigThunk(model.preOnboarding.swMerchantId, false))
            dispatch(getMerchantAcquiringThunk(model.preOnboarding.swMerchantId, false))
        }
    }, [model])

    return [form, model]
}

export default MerchantPreOnboardingPage
