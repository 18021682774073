import i18next from "i18next"
import { getKeys, getText } from "swiipe.portal.shared"
import { ReduxDispatch } from "../store/useReduxDispatchShared"

export type TPortalErrorType =
    | "StandardError"
    | "AccountExistsError"
    | "EmailNotConfirmedError"
    | "CompanyAlreadyRegisteredError"
    | "CertificateInvalidError"
    | "PrivateKeyInvalidError"
    | "ApiKeyInvalidError"
    | "ApiKeyEnvironmentError"

export interface ErrorHandler {
    errorType?: TPortalErrorType
    errorCode?: string
    handleError: (err: ServerError, dispatch: ReduxDispatch) => Promise<boolean>
}

export interface ErrorMessageMapping {
    errorType: TPortalErrorType
    // If not defined value will be taken
    // from 'errorMessage' property from 'error' object
    errorMessage?: string
}

export const getErrorMessageToErrorType: () => ErrorMessageMapping[] = () => [
    {
        errorType: "StandardError",
    },
    {
        errorType: "AccountExistsError",
        errorMessage: getText("partnermerchantrelations.accountAlreadyExists"),
    },
]

export interface ServerError {
    isFallbackError: boolean
    errorToAlert: string
    // Portal errors thrown from API
    errorType?: TPortalErrorType
    // Legacy API errors containing format [number]-[errorMessage]
    errorCode?: string
    statusCode?: number
    errorMessage?: string
    rawError: string
    formErrors?: { [name: string]: string }
}

export function parseServerError(error: any, customDefaultError?: string): ServerError {
    if (error.response && error.response.data) {
        const isCustomError =
            Object.prototype.hasOwnProperty.call(error.response.data, "errors") && getKeys(error.response.data.errors).length > 0
        if (isCustomError) {
            const errorType = getKeys(error.response.data.errors)[0]
            const errorMessage = error.response.data.errors[errorType]

            return {
                isFallbackError: false,
                errorToAlert: errorMessage,
                errorType: errorType as TPortalErrorType,
                errorMessage: errorMessage,
                rawError: errorMessage,
            }
        }

        const data = Object.prototype.hasOwnProperty.call(error.response.data, "message")
            ? error.response.data.message
            : error.response.data

        const isHtmlErrorPage = typeof data === "string" && data.indexOf("<html") >= 0
        if (typeof data === "string" && data.indexOf("-") > 0 && !isHtmlErrorPage) {
            const errorDataParts = data.split("-")
            const errorCode = errorDataParts[0]
            const errorMessage = errorDataParts[1]
            return {
                isFallbackError: false,
                errorToAlert: getErrorFromCode("errors." + errorCode),
                errorCode,
                errorMessage,
                rawError: error + "",
            }
        }

        if (typeof data === "string" && !isHtmlErrorPage) {
            const errorMessage = data
            return {
                isFallbackError: false,
                errorToAlert: errorMessage,
                rawError: errorMessage,
            }
        }
    }
    // TODO: Check for form errors

    return {
        isFallbackError: !customDefaultError,
        errorToAlert: customDefaultError || getText("common.error"),
        rawError: error + "",
        statusCode: error.response?.status,
    }
}

function getErrorFromCode(errorCode: string) {
    const value = i18next.t(errorCode)
    if (errorCode === value) {
        // Not found
        return i18next.t("common.error")
    }
    return value
}
